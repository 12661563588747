import React, {useEffect,useState} from 'react'
import axios from 'axios';
import {  useParams } from "react-router-dom";
import Header from '../Header';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import {useAuthUser} from 'react-auth-kit'
import Manage_clients from './Manage_clients';
import Head from '../Head';


const Register = () => {

 




  const [out, setOutput] = useState({
    
 
    name:'',
    address:'',
    location:'',
    phone1:'',
    phone2:'',
    detail:'',
  
  
  });
  
  
  
 




  
  const [loading, setLoading] = useState(false);
  
  
  
  
  
  
  const onChangeValue = (e) => {
    setOutput({
      ...out,
      [e.target.name]:e.target.value
    });
  
    
  } 
  
  
  
  const add = async(event) => {
    setLoading(true);
     
    try {
      event.preventDefault();
      event.persist();
  
      var formData = new FormData();
 
   
      formData.append("name",out.name); 
      formData.append("email",out.address); 
    
      formData.append("phone",out.phone1); 
      formData.append("password",out.phone2); 
  

      axios.post(`https://future-swift.com/api/register.php`,formData )
      .then(res => {
            setLoading(false);
        alert(res.data.msg);
        
       // Navigate("/");
        window.location.reload(false);
       
       })
    } catch (error) { throw error;}    
  
  };
  
  



  return (
    <div>
     
  <Form dir="RTL"  style={{width:'90%'}} onSubmit={add}>
  <div dir='' class="form-group">
        <Form.Group as={Col} controlId="formGridEmail">
          <Form.Label>الأسم</Form.Label>
          <Form.Control name='name' onChange={onChangeValue} required type="text" placeholder="اسم العميل" />
        </Form.Group>
        <hr></hr>
     
      <Form.Group className="mb-3" controlId="formGridAddress1">
        <Form.Label>بريد</Form.Label>
        <Form.Control name='address' onChange={onChangeValue} required placeholder="صنعاء - شارع حده ...الخ" />
      </Form.Group>
      <hr></hr>
    
<hr></hr>
      <Row className="mb-2">
        <Form.Group as={Col} controlId="formGridCity">
          <Form.Label>رقم تلفون </Form.Label>
          <Form.Control name='phone1' onChange={onChangeValue} required type="tel"/>
        </Form.Group>

        <Form.Group as={Col} controlId="formGridState">
          <Form.Label> كلمة السر  </Form.Label>
          <Form.Control name='phone2' onChange={onChangeValue}  type="text"/>
        </Form.Group>


      </Row>
<hr></hr>

      <hr></hr>
      <Button type="submit">
        اضافة
      </Button>
      </div>







</Form>
    </div>
  );
};
export default Register