
/*
import Home from "./pages/Building/Home";
import Building from "./pages/Building/Building";
import Floor from "./pages/Building/Apartments";

import Adddesign from "./pages/b_plan/Adddesign";
import Apart_sec from "./pages/b_plan/Apart_sec";
import Apart_des from "./pages/b_plan/Apart_des";

import Apart_utl from "./pages/finishes/apart_utl";
import Utilities from "./pages/finishes/Utilties";
import Getsubcat from "./pages/finishes/Getsubcat";

import Apartment from "./pages/Building/Apartment";
import Add_group from "./pages/b_plan/Add_group";
import Add_building from "./pages/Building/Add_building";
import Add_subcat from "./pages/b_plan/Add_subcat";
import Add_subutl from "./pages/finishes/Add_subutl";
import Dashboard from "./pages/Dashboard";

import Clients_ from "./pages/Clients/Clients_";
import Clients_purchases from "./pages/Clients/Clients_purchase";
import Clients_acc from "./pages/Clients/Clients_acc";
import Clients_pay from "./pages/Clients/Clients_pay";
import Clients_special from "./pages/Clients/Clients_special";
import Clients_item from "./pages/Clients/Clients_item";
import Get_operation from "./pages/operations/Get_operations";
import Users_dash from "./pages/users/Users_dash";
import Users from "./pages/users/Users";
import Report_dash from "./pages/reports/Report_dash";
import Purchases from "./pages/reports/Purchaces";
import Accounts from "./pages/reports/Accounts";
import Reserved from "./pages/reports/Reserved";
import Available from "./pages/reports/Available";
*/
import { Route, Routes } from "react-router-dom";
import "./App.css";
import { RequireAuth } from "react-auth-kit";
import Profile from "./pages/Profile";
import Signin from "./pages/Signin";
import Clients from "./pages/future/Clients";
import Register from "./pages/future/register";
function App() {


/*


<Route path="/floor/:fid/:fn" element={<RequireAuth loginPath="/login"><Floor /></RequireAuth>} />

<Route path="/building/:ids" element={<RequireAuth loginPath="/login"><Building /></RequireAuth>} />



<Route path="/addbuilding" element={<RequireAuth loginPath="/login"><Add_building /></RequireAuth>} />



<Route path="/addsubcat" element={<RequireAuth loginPath="/login"><Add_subcat /></RequireAuth>} />


<Route path="/addsubutl" element={<RequireAuth loginPath="/login"><Add_subutl /></RequireAuth>} />


<Route path="/dashboard" element={<RequireAuth loginPath="/login"><Dashboard /></RequireAuth>} />


<Route path="/" element={<RequireAuth loginPath="/login"><Home /></RequireAuth>} />

<Route path="/d_group/manage/:id" element={<RequireAuth loginPath="/login"><Adddesign /></RequireAuth>} />

<Route path="/d_group" element={<RequireAuth loginPath="/login"><Add_group /></RequireAuth>} />

<Route path="/apart_sec" element={<RequireAuth loginPath="/login"><Apart_sec /></RequireAuth>} />

<Route path="/apart_des/:as_id/:num" element={<RequireAuth loginPath="/login"><Apart_des /></RequireAuth>} />


<Route path="/apart_utl" element={<RequireAuth loginPath="/login"><Apart_utl /></RequireAuth>} />

<Route path="/utilities/:d_id/:ad_id/:num" element={<RequireAuth loginPath="/login"><Utilities /></RequireAuth>} />


<Route path="/utilities/:d_id/:ad_id/add_utl/:utl_id/:num/:name/:img/" element={<RequireAuth loginPath="/login"><Getsubcat /></RequireAuth>} />
 

<Route path="/apartment/:apart_id/:as_id/:apart_num/:d_id/:ad_id/:dir/:img/:a_num" element={<RequireAuth loginPath="/login"><Apartment /></RequireAuth>} />



<Route path="/clients" element={<RequireAuth loginPath="/login"><Clients /></RequireAuth>} />



<Route path="/clients/detail" element={<RequireAuth loginPath="/login"><Clients_ /></RequireAuth>} />


<Route path="/clients/purchase" element={<RequireAuth loginPath="/login"><Clients_purchases /></RequireAuth>} />



<Route path="/clients/payments" element={<RequireAuth loginPath="/login"><Clients_pay/></RequireAuth>} />


<Route path="/clients/account" element={<RequireAuth loginPath="/login"><Clients_acc /></RequireAuth>} />



<Route path="/clients/special" element={<RequireAuth loginPath="/login"><Clients_special /></RequireAuth>} />


<Route path="/clients/special/items/:so_id" element={<RequireAuth loginPath="/login"><Clients_item /></RequireAuth>} />


<Route path="/get_operation" element={<RequireAuth loginPath="/login"><Get_operation /></RequireAuth>} />





<Route path="/users" element={<RequireAuth loginPath="/login"><Users_dash /></RequireAuth>} />



<Route path="/users/detail" element={<RequireAuth loginPath="/login"><Users /></RequireAuth>} />



<Route path="/reports" element={<RequireAuth loginPath="/login"><Report_dash /></RequireAuth>} />


<Route path="/reports/purchase" element={<RequireAuth loginPath="/login"><Purchases /></RequireAuth>} />


<Route path="/reports/reserved" element={<RequireAuth loginPath="/login"><Reserved /></RequireAuth>} />


<Route path="/reports/accounts" element={<RequireAuth loginPath="/login"><Accounts /></RequireAuth>} />


<Route path="/reports/available" element={<RequireAuth loginPath="/login"><Available /></RequireAuth>} />





*/

  return (
    <div className="App">
     <Routes>


<Route path="/" element={<RequireAuth loginPath="/login"><Clients /></RequireAuth>} />



<Route path="/myprofile" element={<RequireAuth loginPath="/login"><Profile /></RequireAuth>} />



<Route path="/register" element={<Register />} />
       <Route path="/login" element={<Signin />} />
  
       </Routes>

    </div>
  );
}

export default App;
