import React, {useEffect,useState,useRef} from 'react'
import axios from 'axios';

import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import {useAuthUser} from 'react-auth-kit'
import ReactToPrint from 'react-to-print';
import DatalistInput from 'react-datalist-input';
import 'react-datalist-input/dist/styles.css';
const Manage_clients = () => {

    const auth = useAuthUser();  




    const [out, setOutput] = useState({

      client: '',
      any: '',

      name:'',
      address:'',
      location:'',
      phone1:'',
      phone2:'',
      detail:'',
    });
    
    
    const [spin, setSpin] = useState(false);
    
    
    const handdle_change = (id) => {
      setOutput({
        ...out,
        ['client']:id
      }); 
     clients1(id);
    }
    
    useEffect( () => {
    
        clients();
      
    }, []); 
    
    
    const [loading, setLoading] = useState(false);
    
    const [type, setType] = useState('');
    const [buyers, setBuyers] = useState([]);
    const [buyers1, setBuyers1] = useState([]);
    const clients = async () => {
               
        setSpin(true)
    
      try {
        
    
       
        axios.get(`https://managers.future-swift.com/api/clients.php?username=`+auth().username+'&key='+auth().key)
        .then(res => {
            if(res.data.success===true){
        setBuyers(res.data.productlist.productdata);
        setBuyers1(res.data.productlist.productdata);
        setType(res.data.type)
    
      }else{
        alert(res.data.msg)
      }
          
              
      setSpin(false)
    
        })
      } catch (error) { throw error;}    
    
    }
    
    
    
    
    const clients1 = async (id) => {
        setSpin(true)
    
    try {
      
    
     
      axios.get(`https://managers.future-swift.com/api/client_one.php?username=`+auth().username+'&key='+auth().key+'&b_id='+id)
      .then(res => {
          if(res.data.success===true){
      setBuyers(res.data.productlist.productdata);
      setType(res.data.type)
    
    }else{
      alert(res.data.msg)
    }
        
        
    setSpin(false)
    
      })
    } catch (error) { throw error;}    
    
    }
    
    
      
      
      const [file,setFile] = useState("");
        
      const setimgfile = (e)=>{
        setFile(e.target.files[0])
      }
      
      
    
    const onChangeValue = (e) => {
      setOutput1({
        ...out1,
        [e.target.name]:e.target.value
      });
    
      
    } 
    
    
    
    const [out1, setOutput1] = useState([]);
    const product = async (id) => {
  
      
        try {
         
      
         
       
          
          axios.get('https://managers.future-swift.com/api/get_client.php?u_id='+id+'&key='+auth().key+'&username='+auth().username)
          .then(res => {
            if(res.data.success === true){
      
            setOutput1(res.data.productlist.productdata[0])
             
         
            }else{
              alert(res.data.msg)
            
            }
          
          })
        } catch (error) { throw error;}    
       
      }
      
      
      
      const update = async() => {
        setLoading(true);
         
         
        try {
    
          var formData = new FormData();
      
          formData.append("username",auth().username); 
        formData.append("key",auth().key); 
        formData.append("id",u_id); 
        formData.append("name",out1.name); 
        formData.append("address",out1.address); 
        formData.append("location",out1.location); 
        formData.append("phone1",out1.phone1); 
        formData.append("phone2",out1.phone2); 
        formData.append("detail",out1.detail); 
      
          axios.post(`https://managers.future-swift.com/api/edit_client.php`, formData)
          .then(res => {
                setLoading(false);
            
                alert(res.data.msg);
            
            window.location.reload(false);
           
           })
        } catch (error) { throw error;}    
      
      };

  const [u_id, setU_id] = useState('');
  
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (id) =>{
   if(window.confirm( ` ${id} هل تريد تعديل العميل ؟   `)) {
  
    setU_id(id)
   product(id)
    setShow(true);
   }
    
  } 

    
    var i=0;
    const tableRef = useRef(null);
    return (
      
      <div >
         {loading ? (
          <div className="loader-container">
          
          <h4 style={{position:'absolute', top:'37%', color:'lightblue'}}>FUTURE SWIFT...</h4>
            <div class="spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
            </div>
            
          ) : ( 
    <div>

     
    <Modal  style={{color:'#999'}} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
        <Modal.Title >تعديل مستخدم </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
      

        <div className="list" style={{marginTop:'90px',marginBottom:'50px', marginLeft:20,marginRight:20}}>
    
    <div dir='rtl' style={{width:'90% ', maxWidth:'1000px'}} class="form-container">
         
             <p  class="title"> اضافة عميل جديد </p>
              
                 <hr></hr>
    
      <Form dir="RTL"  style={{width:'90%'}} onSubmit={update}>
      <div dir='' class="form-group">
            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label>الأسم</Form.Label>
              <Form.Control name='name' value={out1.name} onChange={onChangeValue} required type="text" placeholder="اسم العميل" />
            </Form.Group>
            <hr></hr>
         
          <Form.Group className="mb-3" controlId="formGridAddress1">
            <Form.Label>العنوان</Form.Label>
            <Form.Control name='address' value={out1.address} onChange={onChangeValue} required placeholder="صنعاء - شارع حده ...الخ" />
          </Form.Group>
          <hr></hr>
          <Row className="mb-2">
          <Form.Group className="mb-3" controlId="formGridAddress2">
            <Form.Label>الموقع</Form.Label>
            <Form.Control name='location' value={out1.location} onChange={onChangeValue} type='text' placeholder="رابط الموقع من  Google Map" />
          </Form.Group>
          <a target='_blank' href='https://maps.google.com/'><Button variant="outline-success" style={{width:"200px"}}>Google Map</Button></a>
        
    </Row>
    <hr></hr>
          <Row className="mb-2">
            <Form.Group as={Col} controlId="formGridCity">
              <Form.Label>رقم تلفون 1</Form.Label>
              <Form.Control name='phone1' onChange={onChangeValue}  value={out1.phone1} required type="tel"/>
            </Form.Group>
    
            <Form.Group as={Col} controlId="formGridState">
              <Form.Label> رقم تلفون 2 </Form.Label>
              <Form.Control name='phone2'  value={out1.phone2} onChange={onChangeValue}  type="tel"/>
            </Form.Group>
    
    
          </Row>
    <hr></hr>
    
    <Form.Label>ملاحظة</Form.Label>
    <textarea onChange={onChangeValue}  value={out1.detail} name='detail'></textarea>
          <hr></hr>
          <Button type="submit">
            تحديث
          </Button>
          </div>
    
    
    
    
    
    
    
    </Form>
    </div>
    </div>    
         
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>

        </Modal.Footer>
      </Modal>
   
    
       <div >
      
    
     
       <div className="list" style={{marginTop:90,marginBottom:50}}>
    
         
    <form class="form"  autoComplete='off'>
        <p class="title" dir='rtl'>   العملاء </p>
     
          
         
       <hr></hr>
       <label for="textarea">ابحث واختر العميل...</label>
    
    <DatalistInput
    
    style={{color:'#000',}}
    placeholder="العملاء"
    autoSave='off'
    onSelect={(item) => handdle_change(item.id)}
    items={  buyers1.map(option => (
    type === '1'?
      { id: option.buyer_id, value:option.name }
      :  { id: option.buyer_id, value:option.buyer_id }
      ))
    
    }
    />
    <hr></hr>
    </form>
    
    
    <br></br>
    
    
    </div>
    {
 spin ?  <Spinner style={{marginLeft:'50%'}} animation="border" />
 :
    <div >

    <ReactToPrint
   trigger={() => <button style={{background:'transparent',border:'none',margin:'30px'}} >    <img style={{width:'30px'}} src='https://tower.future-swift.com/tool_img/printer.png' /> </button>}
        content={() => tableRef.current}
      />

    <Table  dir='rtl' responsive striped bordered hover variant="light" ref={tableRef}>
        <thead>
        <tr>
    
    
    
    
    
        <th scope="col">رقم </th>
       
        {type === '1' ?
        <th scope="col">الاسم</th>
          :''
        }

        <th scope="col">العنوان</th>

        <th scope="col">الموقع</th>

        <th scope="col">تلفون 1</th>
        <th scope="col">تلفون 2</th>
        <th scope="col">ملاحظة</th>
        <th scope="col">تاريخ الانشاء</th>
        <th scope="col"> المستخدم </th>
        <th scope="col">تعديل</th>
           
       
       
      
            </tr>
            </thead>
      <tbody>
    
      {buyers.map((item,index)=>(
       
       
    
            <tr>
         
            <td>{item.buyer_id}</td>
         
            {type === '1' ?
            <td>{item.name}</td>
          :''
        }

        <td>{item.address}</td>
        <td><a className="imglink1" title='Dashboard' target='_blank' href={item.location}>
 <img src=".././map.png" style={{width:'40px' ,marginRight:13}}/> 
 </a></td>
        <td>{item.phone1}</td>
        <td>{item.phone2}</td>
        <td>{item.detail}</td>
            <td>{item.created_at}</td>
    
            <td>{item.user}</td>
            <td><Button variant="outline-warning" onClick={() =>handleShow((item.buyer_id))}>
          تعديل
        </Button></td> 
           </tr>
         
      
    
     
    
     
    ))}
    
    </tbody>
    
      
    </Table>
    </div>
    }
    <br></br>
    
       </div>
           
             
      
      
    
    
    
      
    </div>
    )}
    
    
    
    

      </div>
    )
    }
    

export default Manage_clients;